import PropTypes from "prop-types";
import React from "react";

import ResponsiveImage from "@/components/ResponsiveImage";
import ResponsiveVideo from "@/components/ResponsiveVideo";

const ResponsiveMedia = ({ className, lazy = true, media }) => {
  switch (media?.sys?.contentType?.sys?.id) {
    case "componentResponsiveImage":
      return (
        <ResponsiveImage {...media?.fields} className={className} lazy={lazy} />
      );
    case "componentResponsiveVideo":
      return (
        <ResponsiveVideo {...media?.fields} className={className} lazy={lazy} />
      );
    default:
      return null;
  }
};

ResponsiveMedia.propTypes = {
  className: PropTypes.string,
  lazy: PropTypes.bool,
  media: PropTypes.object,
};

export default ResponsiveMedia;
