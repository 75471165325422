import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { IoIosVolumeHigh, IoIosVolumeOff } from "react-icons/io";

import { BREAKPOINTS, MESSAGES } from "@/constants";
import useBreakpoint from "@/hooks/useBreakpoint";
import useEqxObj from "@/hooks/useEqxObj";
import useWebView from "@/hooks/useWebView";

import styles from "./ResponsiveVideo.module.scss";

const ResponsiveVideo = ({
  autoplay = true,
  className,
  controls = false,
  defaultVideo,
  hasAudio = false,
  hgVideo,
  lazy = true,
  lgVideo,
  loop = true,
  mdVideo,
  smVideo,
  swapKey,
  swapTimeout = 0,
  title = MESSAGES.TAGLINE,
  xlVideo,
  xsVideo,
}) => {
  const { currentBreakpoint } = useBreakpoint();
  const { isLoading, value: eqxObjValue } = useEqxObj(swapKey, swapTimeout);
  const videoRef = useRef(null);
  const [isInView, setIsInView] = useState(!lazy);
  const [videoUrlsByBreakpointLabel, setVideoUrlsByBreakpointLabel] = useState({
    [BREAKPOINTS.XS.label]: xsVideo?.fields.file.url,
    [BREAKPOINTS.SM.label]: smVideo?.fields.file.url,
    [BREAKPOINTS.MD.label]: mdVideo?.fields.file.url,
    [BREAKPOINTS.LG.label]: lgVideo?.fields.file.url,
    [BREAKPOINTS.XL.label]: xlVideo?.fields.file.url,
    [BREAKPOINTS.HG.label]: hgVideo?.fields.file.url,
  });

  const isWebView = useWebView();
  const [isMuted, setIsMuted] = useState(!isWebView);

  /**
   *
   */
  const videoUrlForBreakpointValue = (currentBreakpoint) => {
    if (!isInView || !currentBreakpoint || isLoading) {
      return null;
    }

    let videoUrl = defaultVideo?.fields?.file?.url;

    BREAKPOINTS.default.some((breakpoint) => {
      if (
        breakpoint.value <= currentBreakpoint?.value &&
        videoUrlsByBreakpointLabel[breakpoint.label]
      ) {
        videoUrl = videoUrlsByBreakpointLabel[breakpoint.label];

        return true;
      }
    });

    return videoUrl;
  };

  /**
   *
   */
  useEffect(() => {
    const currentVideoRef = videoRef?.current;
    let observer;

    if (currentVideoRef) {
      observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsInView(true);
          }
        });
      });

      observer.observe(currentVideoRef);
    }

    return () => {
      observer?.unobserve(currentVideoRef);
    };
  }, []);

  /**
   *
    window.eqxObj = window.eqxObj || {};
    window.eqxObj["web1234-swap-videos"] = {
      none: "https://videos.ctfassets.net/drib7o8rcbyf/1OWsMoHMw1cUxbpkFNDVUD/531ce9155768446c7ab779247000bbfe/WEB_HERO_MOBILE_600x960.mp4",
      md: "https://videos.ctfassets.net/drib7o8rcbyf/3J2gAyGICxLxXeiL8ce3IR/dbee766d059f653bd26d473aee912ced/WEB_HERO_1440_900.mp4",
    };
   */
  useEffect(() => {
    if (eqxObjValue) {
      const videoUrlSwapsByBreakpointLabel = {};

      BREAKPOINTS.default.forEach((breakpoint) => {
        videoUrlSwapsByBreakpointLabel[breakpoint.label] =
          eqxObjValue[breakpoint.label];
      });

      setVideoUrlsByBreakpointLabel(videoUrlSwapsByBreakpointLabel);
    }
  }, [eqxObjValue]);

  return (
    <figure
      className={classNames(styles.responsiveVideo, className)}
      role="none"
    >
      <video
        autoPlay={autoplay}
        controls={isWebView || controls}
        loop={loop}
        muted={isMuted}
        playsInline={true}
        preload={(!lazy).toString()}
        ref={videoRef}
        src={videoUrlForBreakpointValue(currentBreakpoint)}
        title={title}
        type={defaultVideo?.fields?.file?.contentType}
      />

      {hasAudio && (
        <button
          className={styles.muteToggle}
          name={`ResponsiveVideo - ${isMuted ? "VolumeOff" : "VolumeHigh"}`}
          onClick={() => setIsMuted(!isMuted)}
          title={`${isMuted ? "Unm" : "M"}ute video sound`}
        >
          {isMuted ? <IoIosVolumeOff /> : <IoIosVolumeHigh />}
        </button>
      )}
    </figure>
  );
};

ResponsiveVideo.propTypes = {
  autoplay: PropTypes.bool,
  className: PropTypes.string,
  controls: PropTypes.bool,
  defaultVideo: PropTypes.object.isRequired,
  hasAudio: PropTypes.bool,
  hgVideo: PropTypes.object,
  lazy: PropTypes.bool,
  lgVideo: PropTypes.object,
  loop: PropTypes.bool,
  mdVideo: PropTypes.object,
  smVideo: PropTypes.object,
  swapKey: PropTypes.string,
  swapTimeout: PropTypes.number,
  title: PropTypes.string,
  xlVideo: PropTypes.object,
  xsVideo: PropTypes.object,
};

export default ResponsiveVideo;
